import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from '@prop-types';
import styled from '@styled-components';

const Img = styled('img')``;

export const Image = ({ alt, className, src, style, ...props }) => (
  <picture className={className}>
    <Img
      alt={alt}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      {...props}
      style={{ backgroundImage: `url(${withPrefix(src)})`, ...style }}
    />
  </picture>
);

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

export default styled(Image)`
  display: block;
  position: relative;

  ${Img} {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
`;
