import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled from 'styled-components';

import Image from 'Components/Image';

const Background = styled('section')``;
const Wrapper = styled('section')``;

const Description = styled('p')``;
const Text = styled('article')``;
const Title = styled('h2')``;

export const Error404 = ({ className }) => (
  <article className={className}>
    <Background />
    <Wrapper>
      <Text>
        <FormattedMessage capitalize component={Title} id="ERROR404.TITLE">
          oops!
        </FormattedMessage>
        <FormattedMessage
          capitalize
          component={Description}
          id="ERROR404.DESCRIPTION"
        >
          something went wrong...
        </FormattedMessage>
      </Text>
      <Image src="/images/404.png" />
    </Wrapper>
  </article>
);

Error404.propTypes = {
  className: PropTypes.string,
};

export default styled(Error404)`
  ${Wrapper} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 11.2rem;
    height: 65vh;
    width: 100%;
  }

  ${Background} {
    background-image: linear-gradient(#003566, #003566),
      linear-gradient(240deg, #00284d, #003566);
    height: 8.2rem;
  }

  & ${Image} {
    min-width: 30rem;
  }
  & ${Text} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & ${Description} {
      font-size: 2.4rem;
    }
    & ${Title} {
      font-size: 5.4rem;
    }
  }
  @media screen and (max-width: 700px) {
    height: 100vh;
  }
`;
